import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import PostContent from "@components/post-content/post-content";

/**
 * Layouts
 */
import Layout from '@layouts/default';


class CookiePage extends React.Component {

  render() {

    const Modules = get(this, 'props.data.strapiArticles.Add_Modules')
    const Page = get(this, 'props.data.strapiArticles')

    return (
      <Layout>

        <SEO title={Page.Meta_Title} description={Page.Meta_Description} />

        <Container className="cookie-policy-content">
          <div className="post-content">
              <div className="post-content__inner">
                  <div>
                    {Modules.map((Add_Modules, i) => {
                    if(i == 0)
                    {
                      var additional_entry_class = ""
                    }
                    else {
                      var additional_entry_class = "entry-heading-head"
                    }
                    return (
                      <>
              
                      <div className={additional_entry_class}>
                        <PostContent Content={Add_Modules.Static_Content} CTA_Label={Add_Modules.CTA_Label} CTA_URL={Add_Modules.CTA_URL}/>
                      </div>

                      </>
                    )
                  })}
                  </div>
              </div>
          </div>
        </Container>
      <section className="section-white cookie-policy-table">
        <Container>
            <Row className="d-flex justify-content-center content">
                <Col lg={12}>

                <div className="cookie-policy-table">
                    <table className="table">
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <th>Function</th>
                                        <th>Duration</th>
                                        <th>Origin</th>
                                    </tr>
                                    <tr>
                                        <td>Random <br class="wr-br" />e.g. <br class="wr-br" />6cbc5627102ac00d<br class="wr-br" />910eb6ea8658d4a8</td>
                                        <td>Visitor session</td>
                                        <td>Session Cookie</td>
                                        <td>This Domain</td>
                                    </tr>
                                    <tr>
                                        <td>_ga</td>
                                        <td>Google Analytics</td>
                                        <td>2 yrs</td>
                                        <td>This Domain</td>
                                    </tr>
                                    <tr>
                                        <td>_gat</td>
                                        <td>Google Analytics</td>
                                        <td>30 mins</td>
                                        <td>This Domain</td>
                                    </tr>
                                    <tr>
                                        <td>gid</td>
                                        <td>Google Tagmanager</td>
                                        <td>2 yrs</td>
                                        <td>googletagmanager.com</td>
                                    </tr>
                                    <tr>
                                        <td>dph</td>
                                        <td>Google Tagmanager</td>
                                        <td>2 yrs</td>
                                        <td>googletagmanager.com</td>
                                    </tr>
                                    <tr>
                                        <td>__unam</td>
                                        <td>Performance Checking</td>
                                        <td>9 mths</td>
                                        <td>starberry.com</td>
                                    </tr>
                                    <tr>
                                        <td>X-Mapping - {`{random string}`}</td>
                                        <td>Visitor session</td>
                                        <td>Session Cookie</td>
                                        <td>This Domain</td>
                                    </tr>
                                    <tr>
                                        <td>aackid</td>
                                        <td>Google Tag Manager</td>
                                        <td>3 mths</td>
                                        <td>googletagmanager.com</td>
                                    </tr>
                                    <tr>
                                        <td>ckid</td>
                                        <td>Google Tag Manager</td>
                                        <td>2 yrs</td>
                                        <td>adaraanalytics.com</td>
                                    </tr>
                                    <tr>
                                        <td>NID</td>
                                        <td>Social Bookmarking</td>
                                        <td>6 mths</td>
                                        <td>google.com/recaptcha</td>
                                    </tr>
                                    <tr>
                                        <td>favourites</td>
                                        <td>Wishlist</td>
                                        <td>1 yr</td>
                                        <td>This Domain</td>
                                    </tr>
                                </tbody>
                            </table><br /><br /><br /><br />
                        </div>
                </Col>
            </Row>
        </Container>
      </section>

      </Layout>
    )
  }
}

export default CookiePage


export const pageQuery = graphql`
  query CookiePageQuery {


  strapiArticles(id: {eq: "Articles_5f8802543566b7624363e3fc"}) {
    Meta_Description
    Meta_Title
    Pagename
    Add_Modules {
      Static_Content
    }
  }



  }
`
